import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Ankita1 from "../assets/Ankita/ankita1.png";
import Flim from "../assets/others/flim.svg";
import Info from "../assets/others/info.svg";
import Triangle from "../assets/others/triangle.svg";
import Ankita2 from "../assets/Ankita/ankita2.png";
import Reel from "../assets/Ankita/reel.MP4"
import List1 from "../assets/Ankita/list1.png";
import List1First from "../assets/Ankita/list1first.png";
import List1Related from "../assets/Ankita/list1Related.png";
import List4Related from "../assets/Ankita/list4related.png";
import List4First from "../assets/Ankita/list4first.png";
import List6First from "../assets/Ankita/list6first.png";
import List2 from "../assets/Ankita/list2.png";
import List3 from "../assets/Ankita/list3.png";
import List4 from "../assets/Ankita/list4.png";
import List5 from "../assets/Ankita/list5.png";
import List6 from "../assets/Ankita/list6.png";
import List7 from "../assets/Ankita/list7.png";
import { useNavigate } from "react-router-dom";
import AllAboutLove from "../assets/single/allaboutlove.mp3";

import { motion, useAnimation } from "framer-motion";
import MovieDetail from "../modals/MovieDetail/MovieDetail";
const Home = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const maincomponent = [
    {
      bg: Ankita1,
      desc: "In a world where tech meets justice, Ankita, the law ninja, meets Dhairya, the designer geek ! Watch as they trade witty one-liners and engage in epic meme battles while falling head over heels in algorithms.",
      title: "ANKITA <br/> kukreja",
      // componet:
    },
  ];

  const recomm = [
    {
      img: List1,
      title: "The Concert Day",
      date: "20th Nov 2022",
      music: AllAboutLove,
      detailTitle: "The Concert Day",
      relatedImage: [List1Related],
      modalImg: List1First,
      detailDesc:
        "The Concert is a live album by American rock band Creedence Clearwater Revival, released by Fantasy Records in October 1980. It was recorded at the Oakland Coliseum in Oakland, California on January 31, 1970.",
    },

    {
      img: List4,
      title: "The Proposal Day",
      date: "15th Oct 2022",
      music: AllAboutLove,
      detailTitle: "The Proposal Day",
      relatedImage: [List4Related],
      modalImg: List4First,
      detailDesc:
        "The Concert is a live album by American rock band Creedence Clearwater Revival, released by Fantasy Records in October 1980. It was recorded at the Oakland Coliseum in Oakland, California on January 31, 1970.",
    },
    {
      img: List6,
      title: "Cute asf 😮‍💨",
      date: "5th July 2023",
      music: AllAboutLove,
      detailTitle: "Adorable asf 😮‍💨",
      relatedImage: [List6],
      modalImg: List6First,
      detailDesc:
        "Abhi time nhi mila likne ka 😤😤, but slowly ek ek krke update ho jaynge",
    },
    {
      img: List7,
      title: "The Actual Art 😮‍💨",
      date: "3rd Sept 2023",
      // music: AllAboutLove,
      video: true,
      videoSrc: Reel,
      detailTitle: "The Actual Art 😮‍💨",
      relatedImage: [List7],
      modalImg: List7,
      detailDesc:
        "Abhi time nhi mila likne ka 😤😤, but slowly ek ek krke update ho jaynge",
    },
    // {
    //   img: List3,
    //   title: "The Social Dilemma",
    //   date: "31st July 2020",
    // },
    {
      img: List5,
      title: "The Social Dilemma",
      date: "31st July 2020",
    },
    {
      img: List6,
      title: "The Social Dilemma",
      date: "31st July 2020",
    },
  ];
  const [music, setMusic] = useState(false);
  const [title, setTitle] = useState();
  const [relatedImage, setRelatedImage] = useState();
  const [modalImg, setModalImg] = useState();
  const [detail, setDetail] = useState();
  const [date, setDate] = useState();
  const [data, setData] = useState();

  return (
    <div className="home-page">
      <Navbar />
      <MovieDetail
        showModal={showModal}
        setShowModal={setShowModal}
        detail={detail}
        music={music}
        title={title}
        relatedImage={relatedImage}
        modalImg={modalImg}
        date={date}
        data={data}
      />
      <div className="home-page-landing">
        <div className="home-page-landing-content">
          <motion.div
            className="content"
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <img src={Flim} alt="" />
            <h1
              dangerouslySetInnerHTML={{ __html: maincomponent[0].title }}
            ></h1>
            <p>{maincomponent[0].desc}</p>
            <div className="btns">
              <button>
                <img src={Triangle} alt="" />
                Play
              </button>
              <button className="secondary">
                <img src={Info} alt="" />
                More Info
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      {/* <div className="recommended">
        <p>Recommended for you 😮‍💨💞 </p>
        <div className="recomm-list">
          {recomm.map((item) => {
            return (
              <div className="recomm-item">
                <img src={item.img} alt="" />
                <div className="recomm-item-text">
                  <h1>{item.title}</h1>
                  <p>{item.date}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="recommended">
        <p>Recommended for you 😮‍💨💞 </p>
        <div className="recomm-list">
          {recomm.map((item) => {
            return (
              <div
                onClick={() => {
                  // navigate("/single", { state: { asset: item.asset } });
                  setShowModal(true);
                  // if (item.music) {
                  setMusic(item.music);
                  // }
                  setTitle(item.title);
                  setDetail(item.detailDesc);
                  setRelatedImage(item.relatedImage);
                  setModalImg(item.modalImg);
                  setDate(item.date);
                  setData(item);
                }}
                className="recomm-item"
              >
                <img src={item.img} alt="" />
                <div className="recomm-item-text">
                  <h1>{item.title}</h1>
                  <p>{item.date}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="recommended">
        <p>Recommended for you 😮‍💨💞 </p>
        <div className="recomm-list">
          {recomm.map((item) => {
            return (
              <div className="recomm-item">
                <img src={item.img} alt="" />
                <div className="recomm-item-text">
                  <h1>{item.title}</h1>
                  <p>{item.date}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default Home;
