import React, { useState } from "react";
import N from "../assets/others/n.svg";
import Triangle from "../assets/others/triangle.svg";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  const predefinedPassword = "dhairya"; // Change this to your actual password

  const handleSignIn = () => {
    if (password === predefinedPassword) {
      setLoggedIn(true);
      localStorage.setItem("dannflix", "true");
      navigate("/");
    } else {
      alert("Incorrect password. Please try again.");
    }
  };
  console.log(loggedIn);
  return (
    <div className="login-page">
      <div className="login-card">
        <img src={N} alt="" />
        <h1>Sign In to DannFlix</h1>
        <form action="">
          <input type="text" placeholder="Enter your name" name="" id="" />
          <input
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter password "
          />
          <button type="button" onClick={handleSignIn}>
            <img src={Triangle} alt="" />
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
