import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { useNavigate } from "react-router-dom";
import Single from "../pages/Single";

export default function Navigation() {
  const navigate = useNavigate();
  const routes = [
    {
      path: "/",
      exact: true,
      name: "Home",
      element: <Home />,
      private: true,
    },
    {
      path: "/login",
      exact: true,
      name: "Login",
      element: <Login />,
      private: false,
    },
    {
      path: "/single",
      exact: true,
      name: "Single",
      element: <Single />,
      private: true,
    },
  ];
  const user = localStorage.getItem("dannflix");
  useEffect(() => {
    if (user) {
      // navigate("/");
    }
  }, [user, navigate]);
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private && !user ? <Navigate to="/login" /> : route.element
            }
          />
        ))}
      </Routes>
    </>
  );
}
