import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: { y: "100vh", opacity: 0 },
  visible: {
    y: "100px",
    opacity: 1,
    transition: { delay: 0.5 },
  },
};
const MovieDetail = ({
  showModal,
  setShowModal,
  detail,
  music,
  title,
  relatedImage,
  modalImg,
  date,
  data,
}) => {
  const nav = useNavigate();
  console.log(relatedImage);

  const audioRef = useRef();

  // Play the audio when the component mounts
  useEffect(() => {
    if (audioRef.current && showModal) {
      audioRef.current.play();
    }
  }, [showModal]);

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className="backdrop"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div variants={modal} className="detail-modal">
            <div className="modal-container">
              {music && (
                <div className="sound-element">
                  <audio ref={audioRef} controls>
                    <source src={music} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
              <div onClick={() => setShowModal(false)} className="close-button">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                      fill="#fff"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="single-details">
                <div className="single-modal-img">
                  {data?.video === true ? (
                    <video width="100%" height="100%" autoPlay>
                      <source src={data?.videoSrc} type="video/mp4" />
                    </video>
                  ) : (
                    <img src={modalImg} alt="" />
                  )}
                </div>
                <div className="modal-desc">
                  <h1>{title}</h1>
                  <p>{date}</p>
                  <span>{detail}</span>
                </div>
                <div className="related-img">
                  <p>Related to selected Date</p>
                  <div className="related-img-list">
                    {relatedImage.map((item) => {
                      return <img src={item} alt="" />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MovieDetail;
