import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Single = () => {
  const location = useLocation();
  const assetState = location.state.asset;

  const audioRef = useRef();

  // Play the audio when the component mounts
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="single-page">
      <div className="sound-element">
        <audio ref={audioRef} controls>
          <source src={assetState} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
};

export default Single;
