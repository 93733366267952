import React from "react";
import Logo from "../assets/others/netflix.svg";
import User from "../assets/Ankita/user.png";
const Navbar = () => {
  return (
    <div className="user-navbar">
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <div className="user">
        Ankita
        <img src={User} alt="" />
      </div>
    </div>
  );
};

export default Navbar;
